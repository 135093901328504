import { useQuery } from "@apollo/client"
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"
import { RouteChildrenProps } from "react-router"
import { AdminSQLMaintPoolsQuery, AdminSQLMaintPoolsQueryVariables } from "../../../__generated__/AdminSQLMaintPoolsQuery"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { ADMIN_MAINT_POOLS_QUERY } from "../queries"
import InfoCard from "./InfoCard"
import { isMarchMadnessMatcher, isOPMMatcher } from "../../../common/common-utils-helpers"
import DefaultPool from "./DefaultPool"
import BracketPool from "./BracketPool"
import OPMPool from "./OPMPool"

export function PoolForm({ pool, props }) {
  const { id, name } = pool

  const [snackBarErrorMessage, setSnackBarErrorMessage] = React.useState("")
  const [snackBarSuccessMessage, setSnackBarSuccessMessage] = React.useState("")

  const poolForm = React.useMemo(() => {
    if (isMarchMadnessMatcher.test(pool.gameInstanceUid)) {
      return <BracketPool pool={pool} setSnackBarSuccessMessage={setSnackBarSuccessMessage} setSnackBarErrorMessage={setSnackBarErrorMessage} />
    } else if (isOPMMatcher.test(pool.gameInstanceUid)) {
      return <OPMPool pool={pool} setSnackBarSuccessMessage={setSnackBarSuccessMessage} setSnackBarErrorMessage={setSnackBarErrorMessage} />
    }
    return (
      <DefaultPool
        pool={pool}
        props={props}
        setSnackBarSuccessMessage={setSnackBarSuccessMessage}
        setSnackBarErrorMessage={setSnackBarErrorMessage}
      />
    )
  }, [pool])

  return (
    <>
      <InfoCard maintenanceTitle={` Pool ID: ${id}`} maintenanceSubtitle={`Pool Name: ${name}`} />

      <br />

      {poolForm}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={!!snackBarSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setSnackBarSuccessMessage("")}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#558b2f",
          }}
          message={snackBarSuccessMessage}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackBarSuccessMessage("")}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={!!snackBarErrorMessage}
        autoHideDuration={6000}
        onClose={() => setSnackBarErrorMessage("")}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#800000",
          }}
          message={snackBarErrorMessage}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackBarErrorMessage("")}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
    </>
  )
}

function MaintPool(props: RouteChildrenProps<any>) {
  const poolId = props.match?.params.poolId
  const poolsQuery = useQuery<AdminSQLMaintPoolsQuery, AdminSQLMaintPoolsQueryVariables>(ADMIN_MAINT_POOLS_QUERY, { variables: { poolId } })

  return (
    <Layout title={`Maintenance - Pool: ` + poolsQuery.data?.pool.name}>
      {(poolsQuery.data?.pool && <PoolForm pool={poolsQuery.data.pool} props={props} />) || <LoadingView />}
    </Layout>
  )
}
export default MaintPool
